import {isDefined, set} from "@vueuse/core";
import {fullHash} from "~/composable/fullHash";

export const useSitesStore = defineStore('sitesStore', () => {
	const siteId = ref(null);
	const zones = ref([]);
	const publishingCategories = ref([]);
	const authStore = useAuthStore();
	const progressStore = useProgressIndicatorStore();

	const getFirstSite = computed(() => toValue(sites).at(0));
	const getFirstZoneFirstPlacement = computed(() => toValue(zones)?.at(0)?.placements.at(0));
	const getAllPlacements = computed(() => {
		return [{ name: 'All', id: '' }, ...toValue(zones).flatMap(site => site.placements)];
	});
	async function getSites() {
		progressStore.updateStatus(true);
		const data = await $fetch('/api/sites/get-sites', {
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
			}
		});
		progressStore.updateStatus(false);
	}

	async function updateListSites() {
		progressStore.updateStatus(true);
		const data = await $fetch('/api/sites/zones', {
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
			}
		});
		set(zones, toValue(data.zones));
		set(siteId, toValue(data.siteId));
		progressStore.updateStatus(false);
	}


	async function deleteSite(siteId: string) {
		progressStore.updateStatus(true);
		await $fetch('/api/sites/delete-site',{
			method: 'POST',
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
				siteId,
			}
		});
		await updateListSites();
		progressStore.updateStatus(false);
	}

	async function worksSite({
		id = undefined,
		name= '',
		active = false,
	}) {
		progressStore.updateStatus(true);
		await $fetch('/api/sites/update-site',{
			method: 'POST',
			query: {
				token: toValue(authStore.token),
				username: toValue(authStore.username),
				siteId: toValue(siteId),
				...(isDefined(id) && { id }),
				name,
				active,
			}
		});
		await updateListSites();
		progressStore.updateStatus(false);
	}

	return {
		zones,
		siteId,
		publishingCategories,
		getFirstSite,
		getFirstZoneFirstPlacement,
		getAllPlacements,
		getSites,
		updateListSites,
		deleteSite,
		worksSite,
	}
})
