export const RANGE: Record<RangeKey, RangeKey> = {
	TODAY: 'TODAY',
	YESTERDAY: 'YESTERDAY',
	CURRENT_WEEK: 'CURRENT_WEEK',
	CURRENT_MONTH: 'CURRENT_MONTH',
	LAST_7_DAYS: 'LAST_7_DAYS',
	LAST_30_DAYS: 'LAST_30_DAYS',
	LAST_MONTH: 'LAST_MONTH',
	LAST_3_MONTH: 'LAST_3_MONTH',
	LAST_6_MONTH: 'LAST_6_MONTH',
	CURRENT_YEAR: 'CURRENT_YEAR',
	LAST_YEAR: 'LAST_YEAR',
	CUSTOM: 'CUSTOM'
};

export const GROUP_RANGE: Record<GroupRangeKey, GroupRangeKey> = {
	NONE: 'NONE',
	HOUR: 'HOUR',
	DAY: 'DAY',
	MONTH: 'MONTH',
	YEAR: 'YEAR'
}

export const CAPPING_TYPE = {
	PLACEMENT_FREQUENCY: 'PLACEMENT_FREQUENCY',
	PLACEMENT_CLICKS: 'PLACEMENT_CLICKS',
	PLACEMENT_ACTIONS: 'PLACEMENT_ACTIONS',
	PLACEMENT_REQUEST: 'PLACEMENT_REQUEST'
}

export const CAPPING_SUBJECT = {
	USER_COOKIE: 'USER_COOKIE',
	USER_SERVER_SIDE: 'USER_SERVER_SIDE',
	IP: 'IP',
	LES_IP: 'LES_IP'
}

export const PERIOD_TYPE  = [
	{
		name: 'Minute',
		value: 'MINUTE',
	},
	{
		name: 'Hour',
		value: 'HOUR',
	},
	{
		name: 'Day',
		value: 'DAY',
	},
]

export const GROUP_BY: Record<GroupBy, GroupBy> = {
	'': '',
	SITE: 'SITE',
	ADVERTISER: 'ADVERTISER',
	COUNTRY: 'COUNTRY',
	BROWSER: 'BROWSER',
	OS_VERSION: 'OS_VERSION' ,
	CARRIER: 'CARRIER',
	OPERATING_SYSTEM: 'OPERATING_SYSTEM'
}

export const DEVICE_LIST = [
	{
		name: 'Desktop',
		value: 'DESKTOP',
	},
	{
		name: 'Tablet',
		value: 'TABLET',
	},
	{
		name: 'Mobile',
		value: 'MOBILE',
	},
	{
		name: 'Smart TV',
		value: 'SMART_TV',
	},
]

export const EXCLUDE = 'EXCLUDE';

export const INCLUDE ='INCLUDE';
export const BROWSER = 'BROWSER';
export const COUNTRY = 'COUNTRY';
export const DEVICE_FORMAT = 'DEVICE_FORMAT';
export const TARGETING_STATUS = {
	ENABLE: 'enable',
	DISABLE: 'disable'
}
export const HADOOP : [string] = ['BROWSER', 'OS_VERSION', 'CARRIER', 'OPERATING_SYSTEM']

